.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: var(--z-fixed);
   }
   
   .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: var(--header-height);
    margin: 1rem;
   }
   
   .nav__logo {
    color: var(--first-color);
    transition: color 0.4s;
    font-size: var(--h2-font-size);
    font-family: var(--second-font);
   }
   
   .nav__toggle,
   .nav__close {
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
   }
   
   .nav__cta {
    background-color: var(--first-color);
    color: var(--title-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 0.75rem 1.5rem;
   }
   .nav__list {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
   }
   
   .nav__link {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
    transition: color 0.4s;
   }
   
   .nav__link:hover {
    color: var(--first-color);
   }
   
   .nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
   }
   
   .show-menu {
    right: 0;
   }

   @media screen and (max-width: 1150px) {
    .nav__menu {
      position: fixed;
      top: 0;
      right: -100%;
      background-color: hsla(44, 71%, 53%, 0.263);
      backdrop-filter: blur(16px);
      -webkit-backdrop-filter: blur(16px);
      width: 80%;
      height: 100%;
      padding: 6rem 3rem 0;
      transition: right 0.4s;
    }
   }

   @media screen and (min-width: 1150px) {
    .nav {
      height: calc(var(--header-height) + 1rem);
    }
    .nav__toggle,
    .nav__close {
      display: none;
    }
   
    .nav__list {
      flex-direction: row;
      column-gap: 2.5rem;
    }
   }